import * as scssStyles from '$CSS/settings.scss';
import styled from '@emotion/styled';

const EncompassPageTitle = (fixedHeight: boolean) => {
    const fixedHeightStyles = `
        height: 90px;
            
        > div:first-of-type {
            padding: 20px 0 0 20px;
        }
    `;

    const nonFixedHeightStyles = `
        > div:nth-of-type(2) {
            margin-top: 0px;
        }
    `;

    return fixedHeight ? fixedHeightStyles : nonFixedHeightStyles;
};

const GeotabPageTitle = `
    padding: 20px;    
    box-sizing: border-box;
`;

type StyledPageTitleProps = {
    fixedHeight: boolean;
};

const StyledPageTitle = styled.div<StyledPageTitleProps>`
    ${(props) => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return GeotabPageTitle;
            case 'encompass':
                return EncompassPageTitle(props.fixedHeight);
            default:
                return GeotabPageTitle;
        }
    }}
`;

const BaseGoBackLink = `
    color: white;
    cursor: pointer;
`;

const EncompassGoBackLink = `
    ${BaseGoBackLink}
    padding: 36px 20px;
    margin-top: 6px;
`;

const StyledGoBackLink = styled.div`
    ${() => {
        switch (scssStyles.styleEnvironment) {
            case 'geotab':
                return BaseGoBackLink;
            case 'encompass':
                return EncompassGoBackLink;
            default:
                return BaseGoBackLink;
        }
    }}
`;

export { StyledPageTitle, StyledGoBackLink};
