import { ConfigService, IConfigServiceInjectedProps } from '$State/ConfigFreezerService';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { React } from '../../Imports';
import { StyledIconButton } from '$Components/Shared/MaterialUIComponents/StyledIconButton';

interface IVideoPromoteProps {
    eventId: number;
    promoteVideo: (eventId: number) => void;
    isDisabled?: boolean;
}

type VideoPromoteProps = IVideoPromoteProps & IVideoRecallServiceInjectedProps & IConfigServiceInjectedProps;

const _VideoPromote = (props: VideoPromoteProps) => {
    const { isDisabled } = props;
    const handleOnClick = (e: any) => {
        e.stopPropagation();
        if (isDisabled) return;
        props.promoteVideo(props.eventId);
    };
    return (
        <StyledIconButton
            icon={faBullhorn}
            title={'Promote Video'}
            isDisabled={isDisabled || false}
            handleOnClick={handleOnClick}
        ></StyledIconButton>
    );
};

export const VideoPromote = VideoRecallService.inject(ConfigService.inject(_VideoPromote));
