import { React } from '../../../Imports';
import { Button, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '../../../MaterialUIComponents';
import { canEditByRole, RolesEnum } from '../../../externals/VerifyRole';
import { faMapMarkerAlt, faUser, faTruck, faCalendarDay, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faStar, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FilterBreadcrumbsMultiselect } from '../../FilterComponents/FilterBreadcrumbsMultiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '../../../state/IntegrationPartnerDataFreezerService';
import { IMultiselectFilterOptions } from '../../FilterComponents/MultiSelectFilterBar';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import InfiniteScroll = require('react-infinite-scroller');
import { TypedSplitTreatments, getSplitUserAttributes, isSplitTreatmentOn, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { useState, useReducer, useEffect } from 'react';
import { VideoEventListBulkDismissModal } from '$Components/VideoEvents/VideoEventList/VideoEventListBulkDismissModal';
import { VideoEventListBulkScoreModal } from '$Components/VideoEvents/VideoEventList/VideoEventListBulkScoreModal';
import { VideoEventListRow } from '../VideoEventListRow/VideoEventListRow';
import {
    IVideoEventFilterServiceMultiselectInjectedProps,
    VideoEventFilterServiceMultiselect,
} from '../../../state/VideoEventFilterFreezerServiceMultiselect';
import {
    VideoEventResponse,
    VideoAssetResponse,
    VideoEventWorkflowStatusIdEnum,
    BulkRequest,
    VideoEventScoreResponse,
    BulkRatingRequest,
} from '$Generated/api';
import * as scssStyles from '../../../css/settings.scss';

interface IVideoEventListMultiSelectBaseProps {
    items: VideoEventResponse[];
    onGridSort: (sortBy: string, sortAsc: boolean) => void;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    loadNextPage: () => void;
    morePagesToLoad: boolean;
    headerOpened: boolean;
    onFlagVideoEvent?: (eventId: number | undefined) => void;
    onRateVideo?: (eventId: number | undefined, rating: number, status: string) => void;
    onDismissVideo?: (eventId: number | undefined) => void;
    handleBulkDismissVideo: (body: BulkRequest | undefined) => Promise<boolean>;
    onClearRating?: (eventid: number | undefined) => void;
    onReopenEvent?: (eventid: number | undefined) => void;
    videoEventStatus?: VideoEventWorkflowStatusIdEnum | undefined;
    filters: IMultiselectFilterOptions;
    onUpdateFilters: (filters: IMultiselectFilterOptions) => void;
    isMobileView: boolean;
    videoEventRatings: VideoEventScoreResponse[];
    handleSubmitBulkScore: (body: BulkRatingRequest) => Promise<boolean>;
    pageNumber: number;
    itemsPerPage: number;
    totalItemsCount: number;
    getFullListOfEventIds: () => Promise<number[]>;
    refreshVideoEventsList: () => void;
    showEventId?: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
    deleteButtonDisabled: boolean;
    bulkDeleteDialogOpen: (open: boolean, selectedIds: number[]) => void;
}

type IVideoEventListMultiSelectProps = IVideoEventListMultiSelectBaseProps &
    IVideoEventFilterServiceMultiselectInjectedProps &
    IIntegrationPartnerDataInjectedProps;

export const enum selectAllCheckBoxStates {
    selectAllTrue = 'SELECT_ALL_TRUE',
    selectAllFalse = 'SELECT_ALL_FALSE',
    selectAllPartial = 'SELECT_ALL_PARTIAL',
}

interface IVideoEventListMultiSelectState {
    selectedRating: number | undefined | null;
    selectedEvents: number[];
}

const styles = require('./VideoEventList.scss') as {
    main: string;
    buttonContainer: string;
    bulkDismissDialog: string;
    confirmButton: string;
    cancelButton: string;
    confirmButtonTaskbar: string;
    cancelButtonTaskbar: string;
    confirmMessageContainer: string;
    confirmMessage: string;
    dismissConfirmationHeader: string;
    dialog: string;
    dialogAction: string;
    filterContainer: string;
    checkbox: string;
    dismissCheckboxSizing: string;
    videoEventList: string;
    row: string;
    eventType: string;
    videoEventStatus: string;
    driver: string;
    mph: string;
    date: string;
    location: string;
    vehicle: string;
    scrollWindow: string;
    scrollWindowReduced: string;
    headerRow: string;
    headerCell: string;
    labelSubtext: string;
    headerLabelAndIcon: string;
    headerIcon: string;
    headerLabel: string;
    headerIconBox: string;
    frozenHeader: string;
    headerLabelAndSubtext: string;
    nonSortHeaderLabelWithIcon: string;
    buttonTaskbar: string;
};

type Action =
    | { type: 'setRating'; payload: number }
    | { type: 'addEvent'; payload: number }
    | { type: 'removeEvent'; payload: number }
    | { type: 'addMultipleEvents'; payload: number[] }
    | { type: 'removeAllEvents' };

const reducer = (state: IVideoEventListMultiSelectState, action: Action) => {
    switch (action.type) {
        case 'setRating':
            return { ...state, selectedRating: action.payload };
        case 'addEvent':
            return { ...state, selectedEvents: [...state.selectedEvents, action.payload] };
        case 'removeEvent':
            const index = state.selectedEvents.indexOf(action.payload);
            return { ...state, selectedEvents: state.selectedEvents.filter((_, i) => i !== index) };
        case 'addMultipleEvents':
            return { ...state, selectedEvents: [...state.selectedEvents, ...action.payload] };
        case 'removeAllEvents':
            return { ...state, selectedEvents: [] };
        default:
            throw new Error(`Unknow action type`);
    }
};
const _VideoEventListMultiSelect = (props: IVideoEventListMultiSelectProps) => {
    const {
        items,
        videoEventRatings,
        handleSubmitBulkScore,
        morePagesToLoad,
        itemsPerPage,
        totalItemsCount,
        getFullListOfEventIds,
        handleBulkDismissVideo,
        refreshVideoEventsList,
        showEventId,
        toggleDeletePopup,
        deleteButtonDisabled,
        bulkDeleteDialogOpen,
    } = props;
    const isMetric = props.integrationPartnerData.getUserIsMetric();
    const speedLabel = isMetric ? 'Kilometers Per Hour' : 'Miles Per Hour';
    const canEdit = canEditByRole([RolesEnum.videoEventEdit]);
    const [isBulkScoreDialogOpen, setIsBulkScoreDialogOpen] = useState(false);
    const [isBulkDismissDialogOpen, setIsBulkDismissDialogOpen] = useState(false);

    const [selectAllCheckBoxState, setSelectAllCheckBoxState] = useState<selectAllCheckBoxStates>(selectAllCheckBoxStates.selectAllFalse);
    const [state, dispatch] = useReducer(reducer, { selectedRating: 1, selectedEvents: [] });

    let scrollParentRef: any;
    const handleRequestSort = (property: string): void => {
        const { onGridSort } = props;
        const { sortAsc, sortBy } = props.videoEventFilterMultiselect.getVideoEventFilter();

        //Default sort to try if a new property
        let isAsc = true;

        //if sorting again on existing property, swap sort direction
        if (property === sortBy) {
            //swap sort order
            isAsc = sortAsc == true ? false : true;
        }

        onGridSort(property, isAsc);
    };

    const handleCloseBulkScoreModal = () => {
        setIsBulkScoreDialogOpen(false);
    };

    const handleCloseBulkDismissModal = () => {
        setIsBulkDismissDialogOpen(false);
    };

    const loadVideoEventsOnScroll = () => {
        props.loadNextPage();
    };

    const handleSelectEvent = (id: number) => {
        const index = state.selectedEvents.indexOf(id);
        index === -1 ? dispatch({ type: 'addEvent', payload: id }) : dispatch({ type: 'removeEvent', payload: id });
    };

    const handleSelectAllEvents = () => {
        switch (selectAllCheckBoxState) {
            case selectAllCheckBoxStates.selectAllTrue:
                setSelectAllCheckBoxState(selectAllCheckBoxStates.selectAllFalse);
                dispatch({ type: 'removeAllEvents' });
                break;
            case selectAllCheckBoxStates.selectAllFalse:
                setSelectAllCheckBoxState(selectAllCheckBoxStates.selectAllTrue);
                var unselectedEvents = items.filter((x) => state.selectedEvents.indexOf(x.id!) === -1).map((item) => item.id!);
                dispatch({ type: 'addMultipleEvents', payload: unselectedEvents });
                break;
            case selectAllCheckBoxStates.selectAllPartial:
                setSelectAllCheckBoxState(selectAllCheckBoxStates.selectAllTrue);
                var unselectedEvents = items.filter((x) => state.selectedEvents.indexOf(x.id!) === -1).map((item) => item.id!);
                dispatch({ type: 'addMultipleEvents', payload: unselectedEvents });
                break;
        }
    };

    const handleCancelButton = () => {
        setSelectAllCheckBoxState(selectAllCheckBoxStates.selectAllFalse);
        dispatch({ type: 'removeAllEvents' });
    };

    const getSelectedEventsCount = (): number => {
        let totalCount = 0;

        switch (selectAllCheckBoxState) {
            case selectAllCheckBoxStates.selectAllTrue:
                totalCount = totalItemsCount;
                break;
            case selectAllCheckBoxStates.selectAllFalse:
                totalCount = state.selectedEvents.length;
                break;
            case selectAllCheckBoxStates.selectAllPartial:
                totalCount = totalItemsCount - (items.length - state.selectedEvents.length);
                break;
        }

        return totalCount;
    };

    const getSelectedEvents = async (): Promise<number[]> => {
        let events: number[] = [];

        switch (selectAllCheckBoxState) {
            case selectAllCheckBoxStates.selectAllTrue:
                events = await getFullListOfEventIds();
                break;
            case selectAllCheckBoxStates.selectAllFalse:
                events = state.selectedEvents;
                break;
            case selectAllCheckBoxStates.selectAllPartial:
                const fullListOfEventIds = await getFullListOfEventIds();
                const unselectedEvents = items.filter((x) => state.selectedEvents.indexOf(x.id!) === -1).map((item) => item.id!);
                const finalList = fullListOfEventIds.filter((x) => unselectedEvents.indexOf(x) === -1);
                events = finalList;
                break;
        }

        return Promise.resolve(events);
    };

    useEffect(() => {
        if (
            selectAllCheckBoxState === selectAllCheckBoxStates.selectAllTrue ||
            selectAllCheckBoxState === selectAllCheckBoxStates.selectAllPartial
        ) {
            if (state.selectedEvents.length !== items.length) setSelectAllCheckBoxState(selectAllCheckBoxStates.selectAllPartial);
            else if (state.selectedEvents.length === items.length) setSelectAllCheckBoxState(selectAllCheckBoxStates.selectAllTrue);
        }
    }, [state.selectedEvents]);

    useEffect(() => {
        if (selectAllCheckBoxState === selectAllCheckBoxStates.selectAllTrue) {
            dispatch({ type: 'removeAllEvents' });
            dispatch({ type: 'addMultipleEvents', payload: items.map((item) => item.id!) });
        } else if (selectAllCheckBoxState === selectAllCheckBoxStates.selectAllPartial) {
            const lastPageOfEvents = items.slice(items.length - itemsPerPage, items.length).map((item) => item.id!);
            dispatch({ type: 'addMultipleEvents', payload: lastPageOfEvents });
        }
    }, [items]);

    const rowHeaders = [
        {
            icon: '',
            labelSubtext: '',
            label: 'Event Type',
            id: 'eventTypeCleanedUp',
            isSortable: true,
        },
        {
            icon: '',
            labelSubtext: '',
            label: 'Event Status',
            id: 'statusdescription',
            isSortable: true,
        },
        {
            icon: <FontAwesomeIcon icon={faUser} className={styles.headerIcon} color={scssStyles.customColor2} />,
            labelSubtext: '',
            label: 'Driver',
            id: 'driver',
            isSortable: true,
        },
        {
            icon: <FontAwesomeIcon icon={faTruck} className={styles.headerIcon} color={scssStyles.customColor2} />,
            labelSubtext: '',
            label: 'Vehicle',
            id: 'vehicle',
            isSortable: true,
        },
        {
            icon: <FontAwesomeIcon icon={faCalendarDay} className={styles.headerIcon} color={scssStyles.customColor2} />,
            labelSubtext: '',
            label: 'Date/Time',
            id: 'date',
            isSortable: true,
        },
        {
            icon: <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.headerIcon} color={scssStyles.customColor2} />,
            labelSubtext: '',
            label: 'Location',
            id: 'location',
            isSortable: false,
        },
        {
            icon: '',
            labelSubtext: '\n(Start, End, Change)',
            label: 'Miles Per Hour',
            id: 'mph',
            isSortable: false,
        },
    ];

    if (showEventId) {
        rowHeaders.splice(1, 0, {
            icon: '',
            labelSubtext: '',
            label: 'ID',
            id: 'displayId',
            isSortable: true,
        });
    }

    return (
        <>
            <div className={styles.main}>
                {state.selectedEvents.length > 0 && ( //checks if any items are currently selected - and if there are, renders the dismiss bar.
                    <div className={`${styles.filterContainer} ${styles.bulkDismissDialog}`}>
                        <div>
                            <Button className={styles.cancelButtonTaskbar} onClick={handleCancelButton}>
                                Cancel
                            </Button>
                        </div>
                        <div style={{ display: 'flex', paddingRight: '30px' }}>
                            <Button className={styles.buttonTaskbar} onClick={() => setIsBulkScoreDialogOpen(true)}>
                                <FontAwesomeIcon icon={faStar} />
                                Score
                            </Button>
                            <Button className={styles.buttonTaskbar} onClick={() => setIsBulkDismissDialogOpen(true)}>
                                <FontAwesomeIcon icon={faTimes} /> Dismiss
                            </Button>

                            <TypedSplitTreatments names={[splitTreatmentNames.videoEventDelete]} attributes={getSplitUserAttributes()}>
                                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                    return (
                                        (isReady || isTimedout) &&
                                        isSplitTreatmentOn(treatments[splitTreatmentNames.videoEventDelete]) && (
                                            <Button
                                                className={styles.buttonTaskbar}
                                                onClick={async () => {
                                                    const selectedIds = await getSelectedEvents();
                                                    bulkDeleteDialogOpen(true, selectedIds);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrashCan} /> Delete
                                            </Button>
                                        )
                                    );
                                }}
                            </TypedSplitTreatments>
                        </div>
                    </div>
                )}
                <div
                    className={props.headerOpened ? styles.scrollWindowReduced : styles.scrollWindow}
                    id={'videoEventListScrollWindow'}
                    ref={(ref) => (scrollParentRef = ref)}
                >
                    {props.isMobileView && <FilterBreadcrumbsMultiselect filters={props.filters} onUpdateFilters={props.onUpdateFilters} />}
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadVideoEventsOnScroll}
                        hasMore={morePagesToLoad}
                        loader={
                            <div key={'videoEventListloader'} className="loader">
                                Loading ...
                            </div>
                        }
                        useWindow={false}
                        getScrollParent={() => scrollParentRef}
                    >
                        <Table className={styles.videoEventList}>
                            <TableHead className={styles.frozenHeader}>
                                <TableRow className={scssStyles.styleEnvironment == 'encompass' ? styles.headerRow : ''}>
                                    {canEdit && (
                                        <TableCell style={{ padding: 6, paddingBottom: 12 }}>
                                            <Checkbox
                                                style={{
                                                    transform: 'scale(0.75)',
                                                    marginRight: 8,
                                                    marginLeft: 8,
                                                    padding: 0,
                                                }}
                                                className={styles.checkbox}
                                                onChange={() => handleSelectAllEvents()}
                                                checked={selectAllCheckBoxState === selectAllCheckBoxStates.selectAllTrue}
                                                disableRipple={true}
                                            />
                                        </TableCell>
                                    )}

                                    {/* Headers based off array defined above */}

                                    {rowHeaders.map((head) => {
                                        return (
                                            <TableCell
                                                key={head.id}
                                                sortDirection={
                                                    props.videoEventFilterMultiselect.getVideoEventFilter().sortBy === head.id
                                                        ? props.videoEventFilterMultiselect.getVideoEventFilter().sortAsc
                                                            ? 'asc'
                                                            : 'desc'
                                                        : false
                                                }
                                                style={{ padding: 6, lineHeight: '1.2em' }}
                                            >
                                                {head.isSortable == true ? (
                                                    <TableSortLabel
                                                        active={props.videoEventFilterMultiselect.getVideoEventFilter().sortBy === head.id}
                                                        direction={
                                                            props.videoEventFilterMultiselect.getVideoEventFilter().sortAsc ? 'asc' : 'desc'
                                                        }
                                                        onClick={(): void => handleRequestSort(head.id)}
                                                    >
                                                        <div className={styles.headerCell}>
                                                            <div className={styles.headerLabelAndIcon}>
                                                                {head.icon ? <div className={styles.headerIconBox}> {head.icon} </div> : ''}

                                                                <div className={styles.headerLabel}>{head.label}</div>
                                                            </div>
                                                            <div className={styles.labelSubtext}>
                                                                {head.labelSubtext && scssStyles.styleEnvironment !== 'encompass'
                                                                    ? head.labelSubtext
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </TableSortLabel>
                                                ) : (
                                                    <div className={styles.headerCell}>
                                                        <div className={styles.headerLabelAndIcon}>
                                                            {head.icon ? <div className={styles.headerIconBox}>{head.icon}</div> : null}
                                                            <div
                                                                className={`${head.icon ? styles.nonSortHeaderLabelWithIcon : ''} ${
                                                                    scssStyles.styleEnvironment == 'encompass'
                                                                        ? styles.headerLabel
                                                                        : styles.headerLabelAndSubtext
                                                                }`}
                                                            >
                                                                {head.id === 'mph' ? speedLabel : head.label}
                                                            </div>
                                                        </div>
                                                        <div className={styles.labelSubtext}>
                                                            {head.labelSubtext && scssStyles.styleEnvironment !== 'encompass'
                                                                ? head.labelSubtext
                                                                : null}
                                                        </div>
                                                    </div>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                    {/* Action Items Cell Header */}
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {items.map((row, idx) => {
                                    const assets: VideoAssetResponse[] = row.videoAsset || [];

                                    return (
                                        <VideoEventListRow
                                            videoEventStatus={row.videoEventStatus}
                                            event={row}
                                            key={'eventListRow' + idx}
                                            selectedEventHandler={handleSelectEvent}
                                            selectedEvents={state.selectedEvents}
                                            onSelectVideoEvent={props.onSelectVideoEvent}
                                            onFlagVideoEvent={props.onFlagVideoEvent}
                                            onRateVideo={props.onRateVideo}
                                            onDismissVideo={props.onDismissVideo}
                                            onClearRating={props.onClearRating}
                                            onReopenEvent={props.onReopenEvent}
                                            showEventId={showEventId}
                                            toggleDeletePopup={toggleDeletePopup}
                                            deleteButtonDisabled={deleteButtonDisabled}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </InfiniteScroll>
                    <VideoEventListBulkScoreModal
                        isOpen={isBulkScoreDialogOpen}
                        handleModalClosed={handleCloseBulkScoreModal}
                        selectedEventsCount={getSelectedEventsCount()}
                        videoEventRatings={videoEventRatings}
                        handleSubmit={handleSubmitBulkScore}
                        getSelectedEvents={getSelectedEvents}
                        refreshVideoEventsList={refreshVideoEventsList}
                    />
                    <VideoEventListBulkDismissModal
                        isOpen={isBulkDismissDialogOpen}
                        handleModalClosed={handleCloseBulkDismissModal}
                        selectedEventsCount={getSelectedEventsCount()}
                        handleSubmit={handleBulkDismissVideo}
                        getSelectedEvents={getSelectedEvents}
                        refreshVideoEventsList={refreshVideoEventsList}
                    />
                </div>
            </div>
        </>
    );
};

export const VideoEventListMultiSelect = VideoEventFilterServiceMultiselect.inject(
    IntegrationPartnerDataService.inject(_VideoEventListMultiSelect),
);
