import { FreezerService, managedAjaxUtil, IAjaxState } from 'Imports';
import { PricingPlanApiFactory, PricingPlanFeatureTypeEnum, PricingPlanResponse } from '$Generated/api';
import { UserManagementService } from '$State/UserManagementFreezerService'; 

const InjectedPropName = 'pricingPlan';

interface IPricingPlanState {
    pricingPlanResults: IAjaxState<PricingPlanResponse>;
    hasError: boolean;
}

class PricingPlanFreezerService extends FreezerService<IPricingPlanState, typeof InjectedPropName> {
    private _pricingPlanResults: Promise<void | PricingPlanResponse> | undefined;

    constructor() {
        super (
            {
                pricingPlanResults: managedAjaxUtil.createInitialState(),
                hasError: false
            },
            InjectedPropName
        );
    }

    public async getPricingPlan(): Promise<void | PricingPlanResponse> {
        this._pricingPlanResults = managedAjaxUtil.fetchResults({
            ajaxStateProperty: 'pricingPlanResults',
            freezer: this.freezer,
            onExecute: (apiOptions, params) => {
                if (params === undefined) {
                    throw new Error('Parameters is undefined');
                }

                const pricingPlanApi = PricingPlanApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
                return pricingPlanApi.apiV1PricingplanGet(params, apiOptions.fetchOptions);
            },
            params: {
                ...UserManagementService.generateIntegrationUserParams(),
            },
            onOk: (data: PricingPlanResponse) => {
                if (data !== undefined) {
                    this.freezer.get().set({ hasError: false });
                }

                return data;
            },
            onError: () => {
                this.freezer.get().set({ hasError: true });
            }
        });

        return this._pricingPlanResults;
    }

    public async hasFeatureType(featureType: PricingPlanFeatureTypeEnum): Promise<boolean> {
        const features = this.freezer.get().pricingPlanResults.data?.features;
        return features ?
            !!features.find((x) => x === featureType)
            : false;
    }
}

export const PricingPlanService = new PricingPlanFreezerService();
export type IPricingPlanServiceInjectedProps = ReturnType<PricingPlanFreezerService['getPropsForInjection']>;
