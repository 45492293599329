import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { React } from 'Imports';
import { StyledPageTitle, StyledGoBackLink } from './PageHeaderStyles';
import { TypedSplitTreatments, getSplitUserAttributes, isSplitTreatmentOn, splitTreatmentNames } from '$Utilities/split-io-sdk';
import { VideoEventService } from '$State/VideoEventFreezerService';
const styles = require('$Components/Shared/PageHeader.scss') as {
    main: string;
    pageTitle: string;
    pageBack: string;
    agreementLink: string;
    splitMessage: string;
    pageSubTitle: string;
    titlebar: string;
};

interface IPageHeaderProps {
    pageTitle: string;
    pageSubTitle?: string;
    navLinkDisplay?: string;
    onSelectVideoEvent?: (value: string, goBack: boolean) => void;
    children?: any;
    fixedHeight?: boolean;
}

export const PageHeader = (props: IPageHeaderProps) => {
    const navigateBack = (): void => {
        if (props.onSelectVideoEvent) {
            VideoEventService.clearVideoEvents();
            props.onSelectVideoEvent('', true);
        }
    };

    const fixedHeight = props.fixedHeight === undefined ? true : props.fixedHeight;
    return (
        <StyledPageTitle className={styles.main} fixedHeight={fixedHeight}>
            <div className={styles.titlebar}>
                <div>
                    <div className={styles.pageTitle}>
                        {props.pageTitle}
                        {props.children}
                    </div>
                    <div className={styles.pageSubTitle}>{props.pageSubTitle}</div>
                </div>
                <div>
                    <div className={styles.splitMessage}>
                        <TypedSplitTreatments names={[splitTreatmentNames.testEasterEgg]} attributes={getSplitUserAttributes()}>
                            {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                return isReady || isTimedout ? (
                                    isSplitTreatmentOn(treatments[splitTreatmentNames.testEasterEgg]) ? (
                                        <div>Split.io is ON</div>
                                    ) : (
                                        <div></div>
                                    )
                                ) : (
                                    <div>Loading...</div>
                                );
                            }}
                        </TypedSplitTreatments>
                        <TypedSplitTreatments names={[splitTreatmentNames.testEasterEgg]} attributes={getSplitUserAttributes()}>
                            {({ isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                                return !isReady && isTimedout ? <div>Split.io has timed out</div> : <div></div>;
                            }}
                        </TypedSplitTreatments>
                    </div>
                    {props.navLinkDisplay ? (
                        <StyledGoBackLink className={styles.pageBack} onClick={(): void => navigateBack()}>
                            {props.navLinkDisplay}
                        </StyledGoBackLink>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </StyledPageTitle>
    );
};
