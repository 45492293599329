import { ConfigService } from '$State/ConfigFreezerService';
import { IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { SplitFactory, SplitTreatments, useClient, SplitSdk } from '@splitsoftware/splitio-react';
import { React } from 'Imports';
import { IsNullOrEmptyString } from '$Utilities/dataModelUtilities';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import * as SplitIO from '@splitsoftware/splitio-react/types/splitio/splitio';

// Client-side API keys for Split.io
const SplitSdkApiKey_Dev = 'oaa62v0nob4barl3n4noofs0luuri0r03tma';
const SplitSdkApiKey_Qas = '80ju1lms8l3ba2j6l909r6cobr38rhop7s8i';
const SplitSdkApiKey_Stg = '6seo78pe0cf52i7oi8r3mlnai6076iba4qbq';
const SplitSdkApiKey_Prd = 'qc29kv0m7osb785ehm33vam0jn4dqb1sg109';

// Debug Mode
const isDebugEnabled = false;

// This is line prevents an error: 'SplitTreatments' cannot be used as a JSX component.
// Need to look into why that error occurs and what the "right" solution is
export const TypedSplitTreatments: any = SplitTreatments;
export const TypedSplitFactory: any = SplitFactory;

export type GetSplitTreatmentsType = { [featureName: string]: boolean };

export const enum splitTreatmentNames {
    testEasterEgg = 'test_easter_egg',
    showUnProcessedEvents = 'unprocessed_events_button',
    dashboardVisibility = 'dashboard_visibility',
    settingsEventConfiguration = 'settings_event_configuration',
    settingsUsers = 'settings_users',
    streamaxHeartbeat = 'streamax_heartbeat',
    cameraLiveVideo = 'camera_live_video',
    gpsTracker = 'gps_tracker',
    modularUIDeviceAssociation = 'modular_UI_Device_Association',
    showInactiveDrivers = 'show_inactive_drivers',
    videoRequestDeviceAssociation = 'video_request_device_association',
    videoeventId = 'videoevent_id',
    videoplayer = 'video_player',
    videoDetailNextPrevWorkflow = 'video_detail_next_prev_workflow',
    videoRecallDetailsPageRedesign = 'video_recall_details_page_redesign',
    videoEventDelete = 'video_event_delete',
}

export function getSplitUserAttributes() {
    let splitAttributes = buildAttributes();
    return splitAttributes;
}

function getCurrentSplitClientKey() {
    let splitAttributes = buildAttributes();
    return splitAttributes.splitKey;
}

export function isSplitTreatmentOn(treatment: any) {
    return isSplitTreatmentOnWithDefault(treatment, false);
}

export function isSplitTreatmentOnWithDefault(treatment: any, controlDefault: any) {
    let result = false;
    if (treatment) {
        result = treatment.treatment === 'on';
        if (!result && treatment.treatment === 'control') {
            if (isDebugEnabled) console.info(`%c [DEBUG] Client has returned as CONTROL`, 'background-color: red; color: white');
            result = controlDefault;
        }
    }
    return result;
}

export function isSplitTreatmentsOnWithDefault(treatments: SplitIO.Treatments | undefined, controlDefault: boolean) {
    let massagedTreamentsObject: GetSplitTreatmentsType = {};
    if (treatments != undefined) {
        let treatmentsObject = Object.keys(treatments);
        treatmentsObject.forEach((treatment) => {
            let isSplitOn = isSplitTreatmentOnWithDefault({ treatment: treatments[treatment] }, controlDefault);
            massagedTreamentsObject[treatment] = isSplitOn;
        });
    }
    return massagedTreamentsObject;
}

function getAPIKey() {
    let apiKey = '';
    if (ConfigService.isDeployEnvironmentDev()) apiKey = SplitSdkApiKey_Dev;
    if (ConfigService.isDeployEnvironmentQas()) apiKey = SplitSdkApiKey_Qas;
    if (ConfigService.isDeployEnvironmentStage()) apiKey = SplitSdkApiKey_Stg;
    if (ConfigService.isDeployEnvironmentProd()) apiKey = SplitSdkApiKey_Prd;
    return apiKey;
}

const buildAttributes = () => {
    let userName = IsNullOrEmptyString(IntegrationPartnerDataService.getUsername())
        ? ''
        : IntegrationPartnerDataService.getUsername().trim().toLowerCase();
    let integrationPlatform = IsNullOrEmptyString(ConfigService.getIntegrationPlatform())
        ? ''
        : ConfigService.getIntegrationPlatform().trim().toLowerCase();
    let fleetId = ConfigService.getFleetIdString(); // This is treated as a number
    let splitKey = 'unknown';
    if (!IsNullOrEmptyString(userName) && !IsNullOrEmptyString(integrationPlatform) && fleetId !== null && fleetId !== '') {
        splitKey = integrationPlatform + ':' + fleetId + ':' + userName;
    }

    let attributes;
    attributes = {
        splitKey: splitKey,
        userName: userName,
        fleetId: fleetId,
        integrationPlatform: integrationPlatform,
    };

    if (attributes.splitKey !== ConfigService.getSplitClientKey()) {
        console.log('Split.IO ClientKey Changed - Updated Attributes:', attributes);

        ConfigService.setSplitClientKey(splitKey);
    }

    return attributes;
};

export function getSplitConfig() {
    return {
        core: {
            authorizationKey: getAPIKey(),
            key: getCurrentSplitClientKey(),
        },
        debug: isDebugEnabled, // Turn this on and you can see trace/debugging messages in the browser JS console to help track down split-related issues.
    };
}

export function getSplitMessage(treatment: any, defaultMessage: string) {
    let configJson = JSON.parse(treatment.config);
    if (configJson) {
        return configJson.message;
    }
    return defaultMessage;
}

// Returns one of two components based on whether or not a treatment is on
export const splitComponentFactory =
    (treatmentName: any) =>
    <TOriginalProps extends {}>(
        SplitOnComponent: React.ComponentClass<TOriginalProps> | React.FunctionComponent<TOriginalProps>,
        SplitOffComponent: React.ComponentClass<TOriginalProps> | React.FunctionComponent<TOriginalProps>,
    ) => {
        type ResultProps = TOriginalProps;

        const result = class SplitComponent extends React.Component<ResultProps> {
            constructor(props: ResultProps) {
                super(props);
            }

            render(): JSX.Element {
                return (
                    <TypedSplitTreatments names={[treatmentName]} attributes={getSplitUserAttributes()}>
                        {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                            return isReady || isTimedout ? (
                                isSplitTreatmentOn(treatments[treatmentName]) ? (
                                    <SplitOnComponent {...this.props} />
                                ) : (
                                    <SplitOffComponent {...this.props} />
                                )
                            ) : (
                                <div>Loading...</div>
                            );
                        }}
                    </TypedSplitTreatments>
                );
            }
        };

        return result;
    };

export const useGetSplitTreatment = () => {
    const client = useClient();

    const getSplitTreatment = async (splitTreament: string, attributes?: SplitIO.Attributes, controlDefault = false): Promise<boolean> =>
        await _isSplitTreatmentOn(splitTreament, controlDefault, attributes);
    const getSplitTreatments = async (
        splitTreaments: Array<string>,
        attributes?: SplitIO.Attributes,
        controlDefault = false,
    ): Promise<GetSplitTreatmentsType> => await _isSplitTreatmentsOn(splitTreaments, controlDefault, attributes);
    const waitReadyOrTimedOutClient = async () =>
        await client?.ready().catch((e: any) => {
            throw e;
        });
    const _isSplitTreatmentOn = async (
        splitTreatment: string,
        controlDefault: boolean,
        attributes?: SplitIO.Attributes,
    ): Promise<boolean> => {
        try {
            await waitReadyOrTimedOutClient();
            if (isDebugEnabled) console.info(`%c [DEBUG] Client is Ready`, 'background-color: orange; color: black');
        } catch (e) {
            if (isDebugEnabled) console.info(`%c [DEBUG] Client has timedout`, 'background-color: orange; color: black');
        } finally {
            return _getTreatment(splitTreatment, controlDefault, attributes);
        }
    };
    const _isSplitTreatmentsOn = async (
        splitTreatments: Array<string>,
        controlDefault: boolean,
        attributes?: SplitIO.Attributes,
    ): Promise<GetSplitTreatmentsType> => {
        try {
            await waitReadyOrTimedOutClient();
            if (isDebugEnabled) console.info(`%c [DEBUG] Client is Ready`, 'background-color: orange; color: black');
        } catch (e) {
            if (isDebugEnabled) console.info(`%c [DEBUG] Client has timedout`, 'background-color: orange; color: black');
        } finally {
            return _getTreatments(splitTreatments, controlDefault, attributes);
        }
    };
    const _getTreatment = async (splitTreatment: string, controlDefault: boolean, attributes?: SplitIO.Attributes): Promise<boolean> => {
        const treatmentResult = client?.getTreatment(splitTreatment, attributes);
        const isSplitOn = isSplitTreatmentOnWithDefault({ treatment: treatmentResult }, controlDefault);
        return isSplitOn;
    };

    const _getTreatments = async (
        splitTreatments: Array<string>,
        controlDefault: boolean,
        attributes?: SplitIO.Attributes,
    ): Promise<GetSplitTreatmentsType> => {
        const treatmentsResult = client?.getTreatments(splitTreatments, attributes);
        const isSplitsOn = isSplitTreatmentsOnWithDefault(treatmentsResult, controlDefault);
        return isSplitsOn;
    };

    return { getSplitTreatment, getSplitTreatments };
};
