import { React, moment } from 'Imports';
import { Grid } from 'MaterialUIComponents';

import { PageHeader } from '$Components/Shared/PageHeader';

import { DriverRankingWidget } from './components/DriverRankingWidget';
import { EventScoreWidget } from './components/EventScoreWidget';
import { EventStatisticsWidget } from './components/EventStatisticsWidget';
import { EventStatusWidget } from './components/EventStatusWidget';

import { DashboardSummaryResponse, StatisticsDashboardFilterModel } from '$Generated/api';
import { DashboardService, IDashboardServiceInjectedProps } from '$State/DashboardFreezerService';
import {
    getSplitMessage,
    getSplitUserAttributes,
    isSplitTreatmentOnWithDefault,
    splitTreatmentNames,
    TypedSplitTreatments,
} from '$Utilities/split-io-sdk';
import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';
import { useEffect, useState } from 'react';
import DateRangeSelector, { DateRange } from '$Components/Dashboards/DateRange/DateRangeSelector';
import StyledAlert from '$Components/Shared/MaterialUIComponents/StyledAlert';
import StyledLoader from '$Components/Shared/MaterialUIComponents/StyledLoader';
import { InfoOutlined } from '@mui/icons-material';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';

const styles = require('./styles/VideoDashboard.scss') as {
    main: string;
    content: string;
    container: string;
    errorMessage: string;
    eventStatus: string;
    eventScore: string;
    driverRanking: string;
    eventStatistics: string;
};

interface IDashboardState {
    data: DashboardSummaryResponse;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDashboardProps {}

type DashboardProps = IDashboardProps & IDashboardServiceInjectedProps & IIntegrationPartnerDataInjectedProps;

const _VideoDashboard = (props: DashboardProps) => {
    const [isLoaderVisible, setIsLoaderVisible] = React.useState(true);
    const [isAlertVisible, setIsAlertVisible] = React.useState(false);
    const [dashboard, setDashboard] = useState<DashboardSummaryResponse>();
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
        startDate: moment().subtract(29, 'days').startOf('day').toDate(),
        endDate: moment().startOf('day').toDate(),
    });

    const hasHistoricData = (dashboardData: DashboardSummaryResponse | null | undefined): boolean => {
        return !(
            dashboardData?.previousSummary?.driverRanking?.ranking == null &&
            dashboardData?.previousSummary?.eventStatus?.totalCount == 0 &&
            dashboardData?.previousSummary?.eventStatistics?.totalCount == 0 &&
            dashboardData?.previousSummary?.eventScore?.totalCount == 0
        );
    };

    /**
     *
     * @returns DateRange - The date range stored in state or the default if it is null/undefined.
     */
    const getDateRange = (): DateRange => {
        let dateRange: DateRange | undefined = props.dashboard.getSelectedDateRange();

        if (dateRange === undefined) {
            dateRange = {
                startDate: moment().subtract(29, 'days').toDate(),
                endDate: moment().toDate(),
            };
        }

        return dateRange;
    };

    /**
     *
     * @returns string - The string containing the group Filter
     */
    const getGroupFilter = (): string => {
        let groupFilter: string | undefined = IntegrationPartnerDataService.getGroupsFilter();

        if (groupFilter === undefined) {
            groupFilter = '';
        }

        return groupFilter;
    };

    const getDashboardData = async (dateRange: DateRange): Promise<void> => {
        setIsLoaderVisible(true);

        const groupFilter = getGroupFilter();

        const dashboardFilterCriteria: StatisticsDashboardFilterModel = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            groupsFilter: groupFilter,
        };

        // This is the call to the freezer service to fetch the data.
        await props.dashboard.getLiveDashboard(dashboardFilterCriteria);
        const dashboardData = props.dashboard.getState().dashboardLiveResults.data;
        setDashboard({ ...dashboardData });

        setSelectedDateRange(dateRange);

        if (!hasHistoricData(dashboardData)) {
            setIsAlertVisible(true);
        } else {
            setIsAlertVisible(false);
        }

        setIsLoaderVisible(false);
    };

    const handleAlertClosed = () => {
        setIsAlertVisible(false);
    };

    useEffect(() => {
        getDashboardData(getDateRange());
    }, []);

    useEffect(() => {
        getDashboardData(getDateRange());
    }, [props.integrationPartnerData.getState().groupsFilter]);

    return (
        <div className={styles.main}>
            <PageHeader pageTitle={'Video Dashboard'} />
            <TypedSplitTreatments names={[splitTreatmentNames.dashboardVisibility]} attributes={getSplitUserAttributes()}>
                {({ treatments, isReady, isTimedout }: ISplitTreatmentsChildProps) => {
                    return isReady || isTimedout ? (
                        isSplitTreatmentOnWithDefault(treatments[splitTreatmentNames.dashboardVisibility], true) ? (
                            <>
                                {!dashboard ? (
                                    <div className={styles.errorMessage}>Loading dashboard data...</div>
                                ) : (
                                    <StyledLoader isVisible={isLoaderVisible}>
                                        <div className={styles.content}>
                                            <StyledAlert
                                                onClose={handleAlertClosed}
                                                textColor="#b67038"
                                                backgroundColor="#ffe9c7"
                                                message="There is not enough historical data to show valid comparisons, so percentages will be N/A."
                                                isVisible={isAlertVisible}
                                                muiIcon={InfoOutlined}
                                                iconColor="#b67038"
                                            ></StyledAlert>
                                            <Grid container spacing={2} className={styles.container}>
                                                <Grid item xs={12}>
                                                    <DateRangeSelector
                                                        handleDateRangeOnChange={getDashboardData}
                                                        minDate={moment().subtract(89, 'days').toDate()}
                                                        maxDate={moment().toDate()}
                                                        defaultDateRange={selectedDateRange}
                                                        showMessage={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} className={styles.eventStatus}>
                                                    {dashboard.requestedSummary?.eventStatus && (
                                                        <EventStatusWidget
                                                            eventStatusRequested={dashboard.requestedSummary?.eventStatus}
                                                            dateRange={selectedDateRange!}
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid item md={12} lg={6} className={styles.eventScore}>
                                                    {dashboard.requestedSummary?.eventScore && dashboard.previousSummary?.eventScore && (
                                                        <EventScoreWidget
                                                            eventScoreRequested={dashboard.requestedSummary?.eventScore}
                                                            eventScorePrevious={dashboard.previousSummary?.eventScore}
                                                            shouldShowPercentage={!isAlertVisible}
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid item md={12} lg={6} className={styles.driverRanking}>
                                                    {dashboard.requestedSummary?.driverRanking && (
                                                        <DriverRankingWidget
                                                            driverRankingRequested={dashboard.requestedSummary?.driverRanking}
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid item xs={12} className={styles.eventStatistics}>
                                                    {dashboard.requestedSummary?.eventStatistics &&
                                                        dashboard.previousSummary?.eventStatistics && (
                                                            <EventStatisticsWidget
                                                                eventStatisticsRequested={dashboard.requestedSummary?.eventStatistics}
                                                                eventStatisticsPrevious={dashboard.previousSummary?.eventStatistics}
                                                                shouldShowPercentage={!isAlertVisible}
                                                            />
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </StyledLoader>
                                )}
                            </>
                        ) : (
                            <div className={styles.errorMessage}>
                                {getSplitMessage(treatments[splitTreatmentNames.dashboardVisibility], 'Dashboard is unavailable')}
                            </div>
                        )
                    ) : (
                        <div></div>
                    );
                }}
            </TypedSplitTreatments>
        </div>
    );
};

export const VideoDashboardPage = IntegrationPartnerDataService.inject(DashboardService.inject(_VideoDashboard));
