import { React, bind } from '../../Imports';
import { VPDropdown, IDropdownItem } from '$Components/FilterComponents/VPDropdown';
import {
    IIntegrationPartnerDataInjectedProps,
    IntegrationPartnerDataService,
    IIntegrationPartnerDataState,
} from '$State/IntegrationPartnerDataFreezerService';
import { SortDropdownItems } from '../../utilities/dataModelUtilities';

interface IDriverDropdownMultiselectProps {
    selectedDriverIds: string[];
    onSelectedDriverChange: (selectedDriver: any[]) => void;
    width?: number;
    includeInactives?: boolean;
    onChangeIncludeInactives: (includeInactives?: boolean) => void;
}

interface IDriverDropdownMultiselectState {
    drivers: any[];
}

class _DriverDropdownMultiselect extends React.PureComponent<
    IDriverDropdownMultiselectProps & IIntegrationPartnerDataInjectedProps & IDriverDropdownMultiselectState
> {
    state = {
        drivers: [],
        includeInactives: false,
    };

    componentDidMount() {
        const { driversResults } = this.props.integrationPartnerData.getState();
        const drivers = driversResults.data ? driversResults.data : [];
        if (drivers.length === 0 || drivers.findIndex((driver) => driver.firstName === 'Unknown') == -1) {
            this.props.integrationPartnerData.on('update', (currentState: IIntegrationPartnerDataState) => {
                let drivers = [{ firstName: 'Unknown', lastName: 'Driver', id: '-1', email: '', isActive: true }];
                drivers = drivers.concat(currentState.driversResults.data || []);
                this.setState({ drivers });
            });
            this.props.integrationPartnerData.getDrivers();
        } else {
            this.setState({ drivers });
        }
    }

    @bind
    formatDriverList(drivers: any[]): IDropdownItem[] {
        const driverList: IDropdownItem[] = [];
        if (drivers !== undefined && drivers !== null && drivers.length !== 0) {
            for (const user of drivers) {
                driverList.push({
                    label: user.firstName + ' ' + user.lastName,
                    value: user.id,
                    isActive: user.isActive,
                });
            }
        }
        return SortDropdownItems(driverList);
    }

    @bind
    handleSelectedItemChange(selectedValue: any[], includeInactives?: boolean) {
            const selectedDriverIds = selectedValue;
            const drivers = this.state.drivers;
            const selectedDrivers: any = drivers.filter((user: any) => selectedDriverIds.indexOf(user.id) > -1).map((driver: any) => driver.id);
            this.props.onSelectedDriverChange(selectedDrivers);
            this.props.onChangeIncludeInactives(includeInactives);
    }

    render(): JSX.Element {
        const { drivers } = this.state;

        return (
                <VPDropdown
                    isCombined={true}
                    name="Driver"
                    items={this.formatDriverList(drivers)}
                    selectedValues={this.props.selectedDriverIds ? this.props.selectedDriverIds : []}
                    onSelectedItemChange={this.handleSelectedItemChange}
                    width={this.props.width}
                    filterInactives= {true}
                    includeInactives= {this.props.includeInactives}
                />
        );
    }
}

export const DriverDropdownMultiselect = IntegrationPartnerDataService.inject(_DriverDropdownMultiselect);
