import { React, bind } from 'Imports';
import { cx } from '@videoplatform/css-helpers';
import { DeviceAssociationService, IDeviceAssociationServiceInjectedProps } from '$State/DeviceAssociationFreezerService';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFleetSettingsServiceInjectedProps, FleetSettingsService } from '$State/FleetSettingsFreezerService';
import LiveVideoPlayer from './LiveVideoPlayer';
import Loader from '$Components/Shared/Loader';
import { StyledPlainCancelButton, StyledPlainSubmitButton } from '$Components/VideoEvents/VideoEventList/VideoEventListStyles';
import * as scssStyles from '$CSS/settings.scss';

const styles = require('../styles/LiveVideo.scss') as {
    main: string;
    mainVideo: string;
    container: string;
    containerLoader: string;
    containerVideo: string;
    containerVideoActions: string;
    title: string;
    cancelButton: string;
    message: string;
    messageIcon: string;
    success: string;
    error: string;
    info: string;
    normal: string;
    gray: string;
    timer: string;
    spacing: string;
    paragraph: string;
    limitInfo: string;
    limitAlert: string;
    closeVideo: string;
};

interface ILiveVideoBaseProps {
    onClose: () => void;
    vehicleCameraPairingId: number | undefined;
    alertDriver: boolean;
}

interface ILiveVideoState {
    isRetrieving: boolean;
    isLiveVideoReady: boolean;
    isLiveVideoUnavailable: boolean;
    isLiveVideoPlaying: boolean;
    liveVideoUrl: string;
    timer: number;
    timerIntervalId: NodeJS.Timeout | undefined;
    progressIntervalId: NodeJS.Timeout | undefined;
    timerText: string;
    message: string;
    usageAvailableSeconds: number;
    startTime: number;
    minutes: number;
    secs: number;
    vehicleCameraPairingId: number;
    sessionId: string;
    cameraDeviceLiveVideoUsageId: number;
    videoLoadedSuccess: boolean;
    liveVideoMax: string;
}

type ILiveVideoProps = ILiveVideoBaseProps & IDeviceAssociationServiceInjectedProps & IFleetSettingsServiceInjectedProps;

class _LiveVideo extends React.Component<ILiveVideoProps, ILiveVideoState> {
    state: ILiveVideoState = {
        isRetrieving: true,
        isLiveVideoReady: false,
        isLiveVideoUnavailable: false,
        isLiveVideoPlaying: false,
        liveVideoUrl: '',
        timer: 0,
        timerIntervalId: undefined,
        progressIntervalId: undefined,
        timerText: '',
        message: '',
        usageAvailableSeconds: 0,
        minutes: 0,
        secs: 0,
        vehicleCameraPairingId: 0,
        startTime: 0,
        sessionId: '',
        cameraDeviceLiveVideoUsageId: 0,
        videoLoadedSuccess: false,
        liveVideoMax: '',
    };
    isEncompass = scssStyles.styleEnvironment == 'encompass';

    async componentDidMount() {
        await this.getLiveVideoUrl();
        await this.getMaxLiveTime();
    }

    componentWillUnmount(): void {
        clearInterval(this.state.timerIntervalId);
        clearInterval(this.state.progressIntervalId);
    }

    componentDidUpdate(prevProps: unknown, prevState: { videoLoadedSuccess: boolean }) {

        if (prevState.videoLoadedSuccess !== this.state.videoLoadedSuccess && this.state.videoLoadedSuccess) {
            this.setState({ startTime: Date.now() }, () => {
                const timerIntervalId = setInterval(() => this.handleTimer(), 1000);
                const progressIntervalId = setInterval(() => this.registerCurrentTime(false), 150000);
                this.registerStartTime();
                this.setState({ timerIntervalId, progressIntervalId });
            });
        }
    }

    async getMaxLiveTime() {
        await this.props.FleetSettings.getFleetSettingsByKeyResults('live_video_monthly_limit_seconds');
        const { getFleetSettingsByKeyResults } = this.props.FleetSettings.getState();
        // Set 0 in case of invalid result
        const settingValue = getFleetSettingsByKeyResults.data?.value ? parseInt(getFleetSettingsByKeyResults.data.value) : 0;

        const minMax = Math.floor(settingValue / 60);
        const secMax = settingValue % 60;

        this.setState({ liveVideoMax: `${minMax > 0 ? minMax + ' minutes ' : ''} ${secMax > 0 ? secMax + ' seconds ' : ''}` });
    }

    async getLiveVideoUrl() {
        if (!this.props.vehicleCameraPairingId) {
            this.setLiveVideoUnavailable();
        } else {
            await this.props.deviceAssociation.getCameraLiveStream(this.props.vehicleCameraPairingId);
            const { cameraLiveStream } = this.props.deviceAssociation.getState();

            if (cameraLiveStream.data && cameraLiveStream.data.success && cameraLiveStream.data.url) {
                const usageAvailableSeconds = cameraLiveStream.data.usageAvailableSeconds ?? 0;
                this.setState(
                    {
                        liveVideoUrl: cameraLiveStream.data.url,
                        minutes: Math.floor(usageAvailableSeconds / 60),
                        secs: usageAvailableSeconds % 60,
                        timer: usageAvailableSeconds,
                        usageAvailableSeconds: usageAvailableSeconds,
                        sessionId: cameraLiveStream.data.sessionId ?? '',
                    },
                    this.setLiveVideoReady,
                );
            } else {
                if (cameraLiveStream.data?.message) {
                    this.setState({ message: cameraLiveStream.data.message });
                }
                this.setLiveVideoUnavailable();
            }
        }
    }

    setLiveVideoReady() {
        this.setState(
            {
                isRetrieving: false,
                isLiveVideoReady: true,
                isLiveVideoUnavailable: false,
                isLiveVideoPlaying: false,
            },
            () => {
                setTimeout(() => {
                    if (!this.state.isLiveVideoPlaying) {
                        this.props.onClose();
                    }
                }, 60000);
            },
        );
    }

    setLiveVideoUnavailable() {
        this.setState({
            isRetrieving: false,
            isLiveVideoReady: false,
            isLiveVideoUnavailable: true,
            isLiveVideoPlaying: false,
        });
    }

    setLiveVideoPlaying() {
        this.setState({
            isRetrieving: false,
            isLiveVideoReady: false,
            isLiveVideoUnavailable: false,
            isLiveVideoPlaying: true,
        });
    }

    @bind
    setVideoLoadedSuccess(): void {
        this.setState({ videoLoadedSuccess: true });
    }

    async handleTimer() {
        const remainingTime = this.state.usageAvailableSeconds - Math.floor((Date.now() - this.state.startTime) / 1000);

        if (this.state.timer > 0) {
            this.setState({ timer: remainingTime }, this.formatTimerText);
        } else {
            this.stopClose();
        }
    }

    formatTimerText() {
        const { timer } = this.state;
        const hours = Math.floor(timer / 3600);
        const minutes = Math.floor((timer % 3600) / 60);
        const secs = timer % 60;

        this.setState({
            timerText: `${hours >= 10 ? hours : '0' + hours}:${minutes >= 10 ? minutes : '0' + minutes}:${secs >= 10 ? secs : '0' + secs}`,
        });
    }

    getRemainingTimeText() {
        const { minutes, secs } = this.state;

        return `${minutes === 0 ? '' : minutes + ' minute' + (minutes > 1 ? 's' : '')}${secs === 0 || minutes === 0 ? '' : ', '}${
            secs > 0 ? secs + ' second' + (secs > 1 ? 's' : '') : ''
        }`;
    }

    stopClose() {
        this.registerCurrentTime(true);
        this.props.onClose();
    }

    async registerCurrentTime(closeStream: boolean) {
        await this.props.deviceAssociation.saveLiveVideoEndTime({
            cameraDeviceLiveVideoUsageId: this.state.cameraDeviceLiveVideoUsageId,
            endTime: new Date(),
            closeStream,
            alertDriver: this.props.alertDriver,
        });
    }

    async registerStartTime() {
        await this.props.deviceAssociation.saveLiveVideoStartTime({
            sessionId: this.state.sessionId,
            vehicleCameraPairingId: this.props.vehicleCameraPairingId,
            startTime: new Date(this.state.startTime),
            alertDriver: this.props.alertDriver,
        });

        const { liveVideoStart } = this.props.deviceAssociation.getState();
        this.setState({
            cameraDeviceLiveVideoUsageId: liveVideoStart.data?.cameraDeviceLiveVideoUsageId ?? 0,
        });
    }

    render(): JSX.Element {
        return (
            <>
                {this.state.isRetrieving && (
                    <div className={styles.main}>
                        <div className={styles.containerLoader}>
                            <Loader />
                        </div>
                        <div className={styles.container}>
                            <span className={cx([styles.title, styles.info])}>Retrieving live footage</span>
                        </div>
                        <div className={styles.spacing}></div>
                        <div className={styles.container}>
                            <span className={styles.cancelButton} onClick={() => this.props.onClose()}>
                                Cancel request
                            </span>
                        </div>
                    </div>
                )}
                {this.state.isLiveVideoReady && (
                    <div className={styles.main}>
                        <div className={styles.container}>
                            <FontAwesomeIcon icon={faCheckCircle} className={cx([styles.messageIcon, styles.success])} />
                        </div>
                        <div className={styles.container}>
                            <span className={cx([styles.title, styles.success])}>Live footage is ready</span>
                        </div>
                        <div className={styles.spacing}></div>
                        <div className={styles.container}>
                            <div className={styles.paragraph}>
                                {this.state.liveVideoMax} of live device footage is available to watch per calendar month.
                                <br /> Your total time remaining for this month is:
                            </div>
                        </div>
                        <div className={styles.container}>
                            <span className={cx([styles.title])}>{this.getRemainingTimeText()}</span>
                        </div>
                        <div className={styles.spacing}></div>
                        <div className={styles.container}>
                            {this.isEncompass ? (
                                <StyledPlainSubmitButton onClick={() => this.setLiveVideoPlaying()}>
                                    Watch Live Footage
                                </StyledPlainSubmitButton>
                            ) : (
                                <span className={styles.cancelButton} onClick={() => this.setLiveVideoPlaying()}>
                                    Watch Live Footage <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                            )}
                        </div>
                        <div className={styles.spacing}></div>
                        <div className={styles.container}>
                            <span
                                style={{ cursor: 'pointer' }}
                                className={this.isEncompass ? styles.cancelButton : ''}
                                onClick={() => this.props.onClose()}
                            >
                                Close
                            </span>
                        </div>
                    </div>
                )}
                {this.state.isLiveVideoUnavailable && (
                    <div className={styles.main}>
                        <div className={styles.container}>
                            <FontAwesomeIcon icon={faExclamationTriangle} className={cx([styles.messageIcon, styles.error])} />
                        </div>
                        <div className={styles.container}>
                            <span className={cx([styles.title, styles.error])}>Unable to retrieve live footage</span>
                        </div>
                        <div className={styles.container}>
                            <p className={styles.message}>
                                {this.state.message && this.state.message.includes('limit exceeded') ? (
                                    <>Monthly live footage limit exceeded for this device.</>
                                ) : (
                                    <>Camera device must be turned ON in order to retrieve footage.</>
                                )}
                            </p>
                        </div>
                        <div className={styles.spacing}></div>
                        <div className={styles.container}>
                            <span className={styles.cancelButton} onClick={() => this.props.onClose()}>
                                Close
                            </span>
                        </div>
                    </div>
                )}
                {this.state.isLiveVideoPlaying && (
                    <div className={styles.mainVideo}>
                        <div className={styles.containerVideo}>
                            <LiveVideoPlayer
                                src={this.state.liveVideoUrl}
                                setVideoLoadedSuccess={this.setVideoLoadedSuccess}
                                onError={() => this.setLiveVideoUnavailable()}
                            />
                        </div>

                        <div className={styles.containerVideoActions}>
                            <div>
                                <div className={cx([styles.container, styles.limitInfo])}>
                                    <small className={styles.gray}>TOTAL REMAINING TIME FOR THIS MONTH</small>
                                </div>
                                <div>
                                    <div className={this.state.timer <= 60 ? styles.error : styles.normal}>
                                        <span className={styles.timer}>{this.state.timerText}</span>
                                        {this.state.timer <= 60 && this.state.timerText != '' && (
                                            <span className={styles.limitAlert}>
                                                Approaching monthly time limit. Once limit is reached, you will no longer be able to view
                                                live footage for this device until next month.
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={cx([styles.container, styles.closeVideo])}>
                                {this.isEncompass ? (
                                    <StyledPlainCancelButton
                                        variant={'outlined'}
                                        onClick={() => this.stopClose()}
                                        style={{ padding: '5px' }}
                                    >
                                        Stop & Close
                                    </StyledPlainCancelButton>
                                ) : (
                                    <span className={styles.cancelButton} onClick={() => this.stopClose()}>
                                        Stop & Close
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

export const LiveVideo = FleetSettingsService.inject(DeviceAssociationService.inject(_LiveVideo));