import NavigationSection from '../VideoDetails/DetailsSidePanel/NavigationSection';
import { React } from '../../../Imports';
import { useEffect, useState } from 'react';

import { IVideoEventServiceInjectedProps, VideoEventService } from '$State/VideoEventFreezerService';

interface IPrevNextProps {
    eventId: string | undefined;
    onSelectVideoEvent: (eventId: string, goBack: boolean, addHistoryState: boolean) => void;
}

const _VideoEventPrevNextSection: React.FC<IPrevNextProps & IVideoEventServiceInjectedProps> = ({
    eventId,
    onSelectVideoEvent,
    videoEvents,
}) => {
    const [sequence, setSequence] = useState(0);
    const [allFilteredIds, setAllFilteredIds] = useState<string[]>([]);

    useEffect(() => {
        const { listofEventIdResult } = videoEvents.getState();
        const allIds = listofEventIdResult?.data?.videoEventDisplayIdList ?? [];
        const currentId = eventId || '';

        setAllFilteredIds(allIds);
        setSequence(allIds.indexOf(currentId) ?? 0);

        return () => {
            setAllFilteredIds([]);
            setSequence(0);
        };
    }, [eventId, videoEvents]);

    const handlePrevNext = (direction: number) => {
        if (allFilteredIds.length <= 1 || (sequence <= 0 && direction == -1) || (sequence >= allFilteredIds.length - 1 && direction == 1))
            return;

        onSelectVideoEvent(allFilteredIds[sequence + direction], false, false);
    };

    return (
        <NavigationSection
            leftTitle="Previous"
            rightTitle="Next Event"
            onLeftClick={() => handlePrevNext(-1)}
            onRightClick={() => handlePrevNext(1)}
            isLeftDisabled={sequence === 0 || allFilteredIds.length <= 1}
            isRightDisabled={sequence === allFilteredIds.length - 1 || allFilteredIds.length <= 1}
        />
    );
};

export const VideoEventPrevNextSection = VideoEventService.inject(_VideoEventPrevNextSection);
