import { React, moment, bind, unit } from 'Imports';
import { buttonColors } from '$Components/Shared/ButtonColors';
import { Card } from 'MaterialUIComponents';
import { DeleteButton } from '$Components/Shared/DeleteButton';
import { FlagButton } from '$Components/Shared/FlagButton';
import { IConfigServiceInjectedProps, ConfigService } from '$State/ConfigFreezerService';
import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '$State/IntegrationPartnerDataFreezerService';
import { RolesEnum, canEditByRole } from 'externals/VerifyRole';
import { VideoDownload } from '$Components/Shared/VideoDownload';
import { VideoEventCardThumbnail } from '$Components/VideoEvents/VideoEventCardThumbnail/VideoEventCardThumbnail';
import { VideoEventFlaggedByUsersCaption } from '$Components/Shared/VideoEventFlaggedByUsersCaption';
import { getStatusLabel } from '$Utilities/videoEventStatusUtility';
import { VideoRatingButton } from '$Components/Shared/VideoRatingButton';
import { VideoEventDescription } from '$Components/Shared/VideoEventDescription';
import { VideoEventIdLabel } from '$Components/Shared/VideoEventIdLabel';
import VideoEventInfo from '$Components/VideoEvents/VideoEventInfo/VideoEventInfo';
import { VideoEventResponse, VideoEventWorkflowStatusIdEnum, UserResponse } from '$Generated/api';
import * as VideoAssets from '$Components/Shared/VideoAssetsUrls';
import * as scssStyles from '$CSS/settings.scss';
import * as _ from 'lodash';

// TODO - the folllowing images are hardcoded, they will need to be dynamic once real stuff available
export const styles = require('$Components/VideoEvents/VideoEventCard/VideoEventCard.scss') as {
    main: string;
    indicator: string;
    card: string;
    thumbnail: string;
    cardBody: string;
    event: string;
    footer: string;
    box: string;
    lastBox: string;
    boxLabel: string;
    boxValue: string;
    eventActions: string;
    addressStreetNumber: number;
    addressRegion: string;
    eventStatus: string;
    eventStatusDescription: string;
    flagButton: string;
    flagIcon: string;
    activeFlagButton: string;
    promotedFooter: string;
    promotedByBox: string;
    flexRow: string;
    boxEncompass: string;
    flaggedByUsersCaptionContainer: string;
    eventInfo: string;
    description: string;
};

interface IVideoEventCardProps {
    event: VideoEventResponse;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    onFlagVideoEvent?: (eventId: number | undefined) => void;
    onRateVideo?: (eventId: number | undefined, rating: number, status: string) => void;
    onDismissVideo?: (eventId: number | undefined) => void;
    onClearRating?: (eventid: number | undefined) => void;
    onReopenEvent?: (eventid: number | undefined) => void;
    handleVideoPlayed?: (eventid: number | undefined) => void;
    deleteVideoRecall?: (eventId: number) => void;
    videoEventStatus?: VideoEventWorkflowStatusIdEnum | undefined;
    toggleDeletePopup: () => void;
    deleteButtonDisabled: boolean;
}

interface IVideoEventCardState {
    selectedRating: number | undefined | null;
    flaggedByUsers: Array<UserResponse>;
    isDismissed: boolean;
    canRate: boolean;
    promotedBy: string;
    cardElement?: Element;
}

class _VideoEventCard extends React.PureComponent<
    IVideoEventCardProps & IConfigServiceInjectedProps & IIntegrationPartnerDataInjectedProps,
    IVideoEventCardState
> {
    private cardElement = React.createRef<HTMLDivElement>();

    state: IVideoEventCardState = {
        selectedRating: this.props.event.score,
        flaggedByUsers: this.props.event.flaggedByUsers ?? ([] as UserResponse[]),
        isDismissed: this.props.event.videoEventStatus == 'Dismissed',
        canRate: this.props.event.videoEventStatus !== 'Dismissed' && this.props.event.videoEventStatus !== 'Completed',
        promotedBy: '',
        cardElement: undefined,
    };

    componentDidMount(): void {
        this.setState({ cardElement: this.cardElement?.current! });
    }

    @bind
    getIndicatorColor(): { background: string; color: string } {
        if (this.props.videoEventStatus != undefined) {
            return buttonColors[this.props.videoEventStatus];
        } else {
            return { background: '', color: '' };
        }
    }

    @bind
    onClearRating(eventId: number | undefined): void {
        if (this.props.onClearRating) {
            this.setState({
                isDismissed: false,
                selectedRating: undefined,
                canRate: true,
            });
            this.props.onClearRating(eventId);
        }
    }

    @bind
    async onReopenEvent(eventId: number | undefined): Promise<void> {
        if (this.props.onReopenEvent) {
            this.setState({
                isDismissed: false,
                selectedRating: undefined,
                canRate: true,
            });
            this.props.onReopenEvent(eventId);
        }
    }

    @bind
    onDismissVideo(eventId: number | undefined): void {
        if (this.props.onDismissVideo) {
            this.setState({
                isDismissed: true,
                selectedRating: null,
                canRate: false,
            });
            this.props.onDismissVideo(eventId);
        }
    }

    @bind
    eventIsFlaggedByCurrentUser(): boolean {
        return (
            _.find(this.state.flaggedByUsers, (user: UserResponse) => {
                return user.integrationProviderSourceId === IntegrationPartnerDataService.getState().currentUserResult.data.id;
            }) !== undefined
        );
    }

    @bind
    onRateVideo(eventId: number | undefined, rating: number, status: string): void {
        if (this.props.onRateVideo && this.state.canRate) {
            this.setState({
                selectedRating: rating,
                isDismissed: false,
            });
            this.props.onRateVideo(eventId, rating, status);
        }
    }

    @bind
    onFlagVideo(eventId: number | undefined): void {
        if (!canEditByRole([RolesEnum.videoEventEdit])) return;
        if (this.props.onFlagVideoEvent) {
            const currentUser = IntegrationPartnerDataService.getState().currentUserResult.data;
            if (this.eventIsFlaggedByCurrentUser()) {
                this.setState({
                    flaggedByUsers: _.filter(this.state.flaggedByUsers, (user: UserResponse) => {
                        return user.email !== currentUser.name;
                    }),
                });
            } else {
                this.setState({
                    flaggedByUsers: this.state.flaggedByUsers.concat([
                        {
                            id: '',
                            email: currentUser.name,
                            integrationProviderSourceId: currentUser.id,
                            displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                        },
                    ]),
                });
            }

            this.props.onFlagVideoEvent(eventId);
        }
    }

    @bind
    handleVideoPlayed(eventId: number | undefined) {
        //make sure the event status is not completed or dismissed
        if (this.props.videoEventStatus !== undefined && this.props.videoEventStatus == 'New') {
            if (this.props.handleVideoPlayed) {
                this.props.handleVideoPlayed(eventId);
            }
        }
    }

    @bind
    deleteVideoRecall(eventId: number): void {
        if (this.props.deleteVideoRecall) {
            this.props.deleteVideoRecall(eventId);
        }
    }

    @bind
    getPromotedByString() {
        const history = this.props.event.actionHistory ? this.props.event.actionHistory : [];
        let promotedBy = '';
        history.forEach((row) => {
            if (row.actionType === 'VideoRecallPromoted' && row.createdBy !== undefined) {
                promotedBy = row.createdBy.displayName || '';
            }
        });

        if (promotedBy !== '') {
            return `Promoted by ${promotedBy}`;
        }
        return '';
    }

    render(): JSX.Element {
        const eventDate = this.props.event.eventStartDate ? moment(this.props.event.eventStartDate) : moment();
        const assets = this.props.event.videoAsset || [];

        const {
            PreviewUrl: vidUrl,
            ThumbnailUrl: thumbnailURL,
            DownloadUrl: downloadUrl,
        } = VideoAssets.findPreviewUrls(assets, this.props.config.getBaseUrl());

        const eventId = this.props.event.id ? this.props.event.id : 0;
        const displayEventId = this.props.event.displayId ? this.props.event.displayId : '';
        const eventTypes = this.props.event.videoEventTypePairings?.map((eventType) => eventType.videoEventTypeString ?? '');

        const eventTypeIconNames =
            this.props.event.videoEventTypePairings?.map((eventType) => eventType.videoEventTypeIconName || '') || [];
        const stepStatus = this.props.event.workflowStep;
        const stepDescription = this.props.event.workflowStepDescription;
        const indicatorColor = this.getIndicatorColor();
        const indicatorText = getStatusLabel(this.props.videoEventStatus as VideoEventWorkflowStatusIdEnum).toUpperCase();

        const isPromotedVideoRecall = eventTypes ? eventTypes.findIndex((type) => type === 'PromotedVideoRecall') !== -1 : false;
        const promotedBy = this.getPromotedByString();

        const isMetric = this.props.integrationPartnerData.getUserIsMetric();
        const speedStart = unit.getSpeedValue(this.props.event.kphStart, isMetric);
        const speedEnd = unit.getSpeedValue(this.props.event.kphEnd, isMetric);
        return (
            <div className={styles.main} ref={this.cardElement}>
                {indicatorColor.background !== '' && (
                    <div className={styles.indicator} style={{ backgroundColor: indicatorColor.background, color: indicatorColor.color }}>
                        {indicatorText}
                    </div>
                )}
                <Card className={styles.card}>
                    {stepStatus !== 'Success' && (
                        <div className={styles.thumbnail}>
                            <div className={styles.eventStatus}>
                                {stepStatus}
                                <div className={styles.eventStatusDescription}>{stepDescription}</div>
                            </div>
                        </div>
                    )}

                    {stepStatus === 'Success' && (
                        <VideoEventCardThumbnail
                            eventId={eventId}
                            displayEventId={displayEventId}
                            thumbnailURL={thumbnailURL}
                            videoURL={vidUrl}
                            showDetailsNavigator={stepStatus === 'Success'}
                            onSelectVideoEvent={this.props.onSelectVideoEvent}
                            handleVideoPlayed={this.handleVideoPlayed}
                        />
                    )}

                    <div
                        className={styles.cardBody}
                        onClick={() => this.props.onSelectVideoEvent(displayEventId, false)}
                        title="View Details"
                    >
                        <div className={styles.event}>
                            <div className={styles.description}>
                                <VideoEventDescription
                                    videoEventTypePairings={this.props.event.videoEventTypePairings}
                                    videoEventTypeString={this.props.event.videoEventTypeString}
                                    isVideoEventDetails={false}
                                />
                                <VideoEventIdLabel displayEventId={displayEventId} />
                            </div>
                        </div>

                        <div className={styles.flexRow}>
                            {this.props.onRateVideo && this.props.onDismissVideo && this.props.onClearRating && (
                                <div className={styles.eventActions}>
                                    <VideoRatingButton
                                        event={this.props.event}
                                        onRateVideo={this.onRateVideo}
                                        onDismissVideo={this.onDismissVideo}
                                        onClearRating={this.onClearRating}
                                        onReopenEvent={this.onReopenEvent}
                                        selectedRating={this.state.selectedRating}
                                        isDismissed={this.state.isDismissed}
                                        canRate={this.state.canRate}
                                        clearIsEnabled={indicatorText !== 'COMPLETED' ? true : false}
                                        anchor={this.state.cardElement}
                                        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                                        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                                    />
                                </div>
                            )}
                            {this.props.onFlagVideoEvent && (
                                <div className={styles.eventActions}>
                                    <FlagButton
                                        eventId={this.props.event.id}
                                        flaggedByUsers={this.state.flaggedByUsers}
                                        onFlagVideoEvent={this.onFlagVideo as (eventId: number | undefined) => void}
                                    />
                                </div>
                            )}
                            {/* as of right now, we don't want to delete recalled videos from the video event page
                                {isPromotedVideoRecall && this.props.event.id && (
                                <div className={styles.eventActions}>
                                    <VideoDelete
                                        eventId={this.props.event.id}
                                        deleteVideoRecall={this.deleteVideoRecall}
                                    />
                                </div>
                            )} */}
                            {stepStatus === 'Success' && (
                                <div className={styles.eventActions}>
                                    <VideoDownload videoUrl={downloadUrl} eventId={this.props.event.id} />
                                </div>
                            )}

                            <div className={styles.eventActions}>
                                <DeleteButton
                                    eventIds={this.props.event.id}
                                    toggleDeletePopup={this.props.toggleDeletePopup}
                                    isDisabled={this.props.deleteButtonDisabled}
                                />
                            </div>
                        </div>

                        <VideoEventInfo
                            event={this.props.event}
                            isPromotedVideoRecall={isPromotedVideoRecall}
                            eventDate={eventDate}
                            className={styles.eventInfo}
                        ></VideoEventInfo>
                    </div>
                    {!isPromotedVideoRecall ? (
                        <div className={styles.footer}>
                            {scssStyles.styleEnvironment === 'encompass' ? (
                                <div className={styles.boxEncompass}>
                                    {`${unit.getSpeedUnitStringEncompass(isMetric)} PER HOUR: `}
                                    <span className={styles.boxValue}>{unit.formatSpeedString(speedStart)}</span>
                                </div>
                            ) : (
                                <>
                                    <div className={styles.box}>
                                        <div className={styles.boxLabel}>{`${unit.getSpeedUnitString(isMetric)} START`}</div>
                                        <div className={styles.boxValue}>{unit.formatSpeedString(speedStart)}</div>
                                    </div>
                                    <div className={styles.box}>
                                        <div className={styles.boxLabel}>{`${unit.getSpeedUnitString(isMetric)} END`}</div>
                                        <div className={styles.boxValue}>{unit.formatSpeedString(speedEnd)}</div>
                                    </div>
                                    <div className={styles.lastBox}>
                                        <div className={styles.boxLabel}>CHANGE</div>
                                        <div className={styles.boxValue} style={{ color: 'red' }}>
                                            {Math.abs(Math.round(speedEnd) - Math.round(speedStart))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={styles.promotedFooter}>
                            <div className={styles.promotedByBox}>{promotedBy}</div>
                        </div>
                    )}
                </Card>
                <div className={styles.flaggedByUsersCaptionContainer}>
                    <VideoEventFlaggedByUsersCaption tooltipPlacement="right" flaggedByUsers={this.state.flaggedByUsers} />
                </div>
            </div>
        );
    }
}

export const VideoEventCard = ConfigService.inject(IntegrationPartnerDataService.inject(_VideoEventCard));
