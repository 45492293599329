import { React, bind } from '../../../Imports';
import { VideoEventResponse } from '$Generated/api';
import { VideoEventCard } from '../VideoEventCard/VideoEventCard';
import { FilterBreadcrumbsMultiselect } from '../../FilterComponents/FilterBreadcrumbsMultiselect';
import { IMultiselectFilterOptions } from '../../FilterComponents/MultiSelectFilterBar';

const InfiniteScroll: any = require('react-infinite-scroller');

interface IVideoEventCardListProps {
    items: VideoEventResponse[];
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    onFlagVideoEvent?: (eventId: number | undefined) => void;
    onRateVideo?: (eventId: number | undefined, rating: number, status: string) => void;
    onDismissVideo?: (eventId: number | undefined) => void;
    onClearRating?: (eventid: number | undefined) => void;
    onReopenEvent?: (eventid: number | undefined) => void;
    handleVideoPlayed?: (eventid: number | undefined) => void;
    loadNextPage: () => void;
    deleteVideoRecall?: (eventId: number) => void;
    morePagesToLoad: boolean;
    headerOpened: boolean;
    shrinkHeight: number;
    filters: IMultiselectFilterOptions;
    onUpdateFilters: (filters: IMultiselectFilterOptions) => void;
    isMobileView: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
    deleteButtonDisabled: boolean;    
}

const styles = require('./VideoEventCardList.scss') as {
    main: string;
    card: string;
    scrollWindow: string;
};

export class VideoEventCardList extends React.Component<IVideoEventCardListProps> {
    private scrollParentRef: any;

    @bind
    loadVideoEventsOnScroll(): void {
        this.props.loadNextPage();
    }

    render(): JSX.Element {
        return (         
            <div
                className={styles.scrollWindow}                
                ref={(ref) => (this.scrollParentRef = ref)}
                style={{height: `calc(100vh - ${this.props.shrinkHeight + 40}px`}}
            >
                {this.props.isMobileView && (
                    <FilterBreadcrumbsMultiselect filters={this.props.filters} onUpdateFilters={this.props.onUpdateFilters} /> 
                )}
                <InfiniteScroll                    
                    pageStart={1}
                    loadMore={this.loadVideoEventsOnScroll}
                    hasMore={this.props.morePagesToLoad}
                    loader={<div key={"videoEventCardListloader"} className="loader">Loading ...</div>}
                    useWindow={false}
                    getScrollParent={() => this.scrollParentRef}
                >
                    <div className={styles.main} >
                        {this.props.items.map((card: VideoEventResponse, idx: number) => (
                            <div  className={styles.card} key={idx + "card"}>
                                <VideoEventCard
                                    videoEventStatus={card.videoEventStatus}
                                    event={card}
                                    onSelectVideoEvent={this.props.onSelectVideoEvent}
                                    onFlagVideoEvent={this.props.onFlagVideoEvent}
                                    onRateVideo={this.props.onRateVideo}
                                    onDismissVideo={this.props.onDismissVideo}
                                    onClearRating={this.props.onClearRating}
                                    onReopenEvent={this.props.onReopenEvent}
                                    handleVideoPlayed={this.props.handleVideoPlayed}
                                    deleteVideoRecall={this.props.deleteVideoRecall}
                                    toggleDeletePopup={this.props.toggleDeletePopup}
                                    deleteButtonDisabled={this.props.deleteButtonDisabled}
                                />
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>    
            </div>            
        );
    }
}
