import { Button, Dialog, DialogActions, DialogTitle } from 'MaterialUIComponents';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { React } from '../../Imports';
import { RolesEnum, canEditByRole } from '$Externals/VerifyRole';
import { StyledIconButton } from './MaterialUIComponents/StyledIconButton';
import { useState } from 'react';

interface IVideoDeleteProps {
    eventId: number;
    deleteVideoRecall: (eventId: number) => void;
    isDisabled?: boolean;
}

const styles = require('./VideoDelete.scss') as {
    dialog: string;
};

type VideoDeleteProps = IVideoDeleteProps & IVideoRecallServiceInjectedProps;

const _VideoDelete = (props: VideoDeleteProps) => {
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const isDisabled = props.isDisabled || !canEditByRole([RolesEnum.videoAdmin]);

    const toggleDeletePopup = (e: any) => {
        e.stopPropagation();
        if (isDisabled) return;
        setShowDeletePopup(!showDeletePopup);
    };

    const deleteVideo = (e: any) => {
        e.stopPropagation();
        props.deleteVideoRecall(props.eventId);
    };

    return (
        <>
            <StyledIconButton handleOnClick={toggleDeletePopup} title="Delete Video" icon={faTimesCircle} isDisabled={isDisabled} />
            <Dialog open={showDeletePopup} onClick={(e) => e.stopPropagation()} className={styles.dialog}>
                <DialogTitle>Delete Video Recall?</DialogTitle>
                <DialogActions>
                    <Button onClick={deleteVideo}>Delete</Button>
                    <Button onClick={toggleDeletePopup}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const VideoDelete = VideoRecallService.inject(_VideoDelete);
