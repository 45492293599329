import { React } from '../../../Imports';
import NavigationSection from '../VideoDetails/DetailsSidePanel/NavigationSection';

import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';

interface IPrevNextProps {
    eventId: string | undefined;
}

const _VideoRecallPrevNextSection: React.FC<IPrevNextProps & IVideoRecallServiceInjectedProps> = ({ eventId, videoRecall }) => {
    const handlePrevNext = (direction: number) => {
        // Empty implementation
    };

    return (
        <NavigationSection
            leftTitle="Previous"
            rightTitle="Next Recall"
            onLeftClick={() => handlePrevNext(-1)}
            onRightClick={() => handlePrevNext(1)}
            isLeftDisabled={true}
            isRightDisabled={true}
            leftTooltip="This feature will be available in a future release"
            rightTooltip="This feature will be available in a future release"
        />
    );
};

export const VideoRecallPrevNextSection = VideoRecallService.inject(_VideoRecallPrevNextSection);
