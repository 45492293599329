import { React, moment, bind, unit } from '../../../Imports';
import { Checkbox, TableCell, TableRow } from '../../../MaterialUIComponents';
import * as DateFormatter from '../../Shared/DateFormatter';
import { DeleteButton } from '$Components/Shared/DeleteButton';
import { FlagButton } from '../../Shared/FlagButton';
import { getStatusLabel } from '$Utilities/videoEventStatusUtility';
import { getEventTypeLabels } from '$Utilities/videoEventTypeUtility';

import { IIntegrationPartnerDataInjectedProps, IntegrationPartnerDataService } from '../../../state/IntegrationPartnerDataFreezerService';
import { IConfigServiceInjectedProps, ConfigService } from '../../../state/ConfigFreezerService';
import { RolesEnum, canEditByRole } from '../../../externals/VerifyRole';
import * as VideoAssets from '$Components/Shared/VideoAssetsUrls';
import { VideoDownload } from '../../Shared/VideoDownload';
import { VideoRatingButton } from '../../Shared/VideoRatingButton';
import { VideoEventFlaggedByUsersCaption } from '../../Shared/VideoEventFlaggedByUsersCaption';
import { VideoEventResponse, VideoEventWorkflowStatusIdEnum, UserResponse } from '$Generated/api';
import * as scssStyles from '../../../css/settings.scss';
import * as _ from 'lodash';

// // TODO - the folllowing images are hardcoded, they will need to be dynamic once real stuff available
const styles = require('./VideoEventListRow.scss') as {
    row: string;
    rowSelected: string;
    checkbox: string;
    dismissCheckboxSizing: string;
    eventType: string;
    eventTypeIconCell: string;
    videoEventStatus: string;
    driver: string;
    vehicle: string;
    date: string;
    mph: string;
    mphChangeValue: string;
    actions: string;
    eventActions: string;
    flexRow: string;
    action: string;
    location: string;
    eventTypeIcon: string;
    mphSingleSpeed: string;
    videoEventFlaggedByUsersCaption: string;
    flexRowAllFlagged: string;
    flagButtonBadge: string;
    flagButtonActive: string;
};

interface IVideoEventListRowProps {
    event: VideoEventResponse;
    selectedEventHandler: (id: number) => void;
    selectedEvents: number[];
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    onFlagVideoEvent?: (eventId: number | undefined) => void;
    onRateVideo?: (eventId: number | undefined, rating: number, status: string) => void;
    onDismissVideo?: (eventId: number | undefined) => void;
    onClearRating?: (eventid: number | undefined) => void;
    onReopenEvent?: (eventid: number | undefined) => void;
    videoEventStatus?: VideoEventWorkflowStatusIdEnum | undefined;
    showEventId?: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
    deleteButtonDisabled: boolean;
}

interface IVideoEventListRowState {
    selectedRating: number | undefined | null;
    flaggedByUsers: Array<UserResponse>;
    isDismissed: boolean;
    canRate: boolean;
    promotedBy: string;
    rowElement?: Element;
    allSelected: boolean;
}

class _VideoEventListRow extends React.PureComponent<
    IVideoEventListRowProps & IConfigServiceInjectedProps & IIntegrationPartnerDataInjectedProps,
    IVideoEventListRowState
> {
    private rowElement = React.createRef<HTMLDivElement>();

    state: IVideoEventListRowState = {
        selectedRating: this.props.event.score,
        flaggedByUsers: this.props.event.flaggedByUsers ?? ([] as UserResponse[]),
        isDismissed: this.props.event.videoEventStatus == 'Dismissed',
        canRate: this.props.event.videoEventStatus !== 'Dismissed' && this.props.event.videoEventStatus !== 'Completed',
        promotedBy: '',
        rowElement: undefined,
        allSelected: false,
    };

    componentDidMount(): void {
        this.setState({ rowElement: this.rowElement?.current! });
    }

    @bind
    onClearRating(eventId: number | undefined): void {
        if (this.props.onClearRating) {
            this.setState({
                isDismissed: false,
                selectedRating: undefined,
                canRate: true,
            });
            this.props.onClearRating(eventId);
        }
    }

    @bind
    async onReopenEvent(eventId: number | undefined): Promise<void> {
        if (this.props.onReopenEvent) {
            this.setState({
                isDismissed: false,
                selectedRating: undefined,
                canRate: true,
            });
            this.props.onReopenEvent(eventId);
        }
    }

    @bind
    onDismissVideo(eventId: number | undefined): void {
        if (this.props.onDismissVideo) {
            this.setState({
                isDismissed: true,
                selectedRating: null,
                canRate: false,
            });
            this.props.onDismissVideo(eventId);
        }
    }

    @bind
    eventIsFlaggedByCurrentUser(): boolean {
        return (
            _.find(this.state.flaggedByUsers, (user: UserResponse) => {
                return user.integrationProviderSourceId === IntegrationPartnerDataService.getState().currentUserResult.data.id;
            }) !== undefined
        );
    }

    @bind
    onRateVideo(eventId: number | undefined, rating: number, status: string): void {
        if (this.props.onRateVideo && this.state.canRate) {
            this.setState({
                selectedRating: rating,
                isDismissed: false,
            });
            this.props.onRateVideo(eventId, rating, status);
        }
    }

    @bind
    onFlagVideo(eventId: number | undefined): void {
        if (!canEditByRole([RolesEnum.videoEventEdit])) return;
        if (this.props.onFlagVideoEvent) {
            const currentUser = IntegrationPartnerDataService.getState().currentUserResult.data;
            if (this.eventIsFlaggedByCurrentUser()) {
                this.setState({
                    flaggedByUsers: _.filter(this.state.flaggedByUsers, (user: UserResponse) => {
                        return user.email !== currentUser.name;
                    }),
                });
            } else {
                this.setState({
                    flaggedByUsers: this.state.flaggedByUsers.concat([
                        {
                            id: '',
                            email: currentUser.name,
                            integrationProviderSourceId: currentUser.id,
                            displayName: `${currentUser.firstName} ${currentUser.lastName}`,
                        },
                    ]),
                });
            }

            this.props.onFlagVideoEvent(eventId);
        }
    }

    @bind
    goToDetails(eventId: string): void {
        this.props.onSelectVideoEvent(eventId, false);
    }

    @bind
    formatGforceEventType(type: string | undefined): string {
        let eventType = String(type);
        eventType = eventType?.includes('Gforce') ? eventType.replace('Gforce', '') : eventType;

        if (eventType?.includes('RightTurn')) {
            eventType = eventType.replace('RightTurn', 'Cornering');
        }

        return eventType;
    }

    @bind
    formatEventTypeToCommaSeperatedValue(eventTypes: string[] | undefined): string {
        if (eventTypes === undefined || eventTypes === null) {
            return 'Unknown';
        }

        const eventTypeLabelArray = eventTypes?.map((x) => {
            if (['forwardcollisionwarning', 'forward collision warning'].indexOf(x.toLowerCase()) !== -1) {
                // Forward Collision Warning and Headway Monitoring are also too long for the title card
                return 'FCW';
            }

            if (x.includes('Gforce')) {
                // Cleaning Gforce reference that comes from VideoEventTypeEnum
                x = x.replace('Gforce', '');
            }

            if (x?.includes('RightTurn')) {
                x = x.replace('RightTurn', 'Cornering');
            }

            x = String(x)
                .replace(/([A-Z])/g, ' $1')
                .trim();
            return x;
        });

        const labels = getEventTypeLabels(eventTypeLabelArray);
        return labels?.join(', ');
    }

    render(): JSX.Element {
        const isSelected = this.props.selectedEvents.some((eventId: any) => eventId === this.props.event.id);
        const assets = this.props.event.videoAsset || [];
        const canEdit = canEditByRole([RolesEnum.videoEventEdit]);

        const { DownloadUrl: vidUrl } = VideoAssets.findPreviewUrls(assets, this.props.config.getBaseUrl());
        const eventId = this.props.event.id ? this.props.event.id : 0;
        const displayEventId = this.props.event.displayId || '';
        const eventStatus = this.props.videoEventStatus !== undefined ? getStatusLabel(this.props.videoEventStatus) : 'Unknown';
        const eventType = this.props.event.videoEventTypePairings?.map((event) => {
            return this.formatGforceEventType(event.videoEventTypeString);
        });
        let indicatorText = this.props.videoEventStatus !== undefined ? this.props.videoEventStatus : '';
        indicatorText = indicatorText.split('_').join(' ').toLocaleUpperCase();
        const isMetric = this.props.integrationPartnerData.getUserIsMetric();
        const speedStart = unit.getSpeedValue(this.props.event.kphStart, isMetric);
        const speedEnd = unit.getSpeedValue(this.props.event.kphEnd, isMetric);
        const eventTypeString = this.formatEventTypeToCommaSeperatedValue(eventType);

        return (
            <TableRow className={`${styles.row} ${isSelected ? styles.rowSelected : ''}`}>
                {canEdit && (
                    <TableCell className={` ${isSelected ? styles.rowSelected : ''} `}>
                        {eventStatus !== 'Dismissed' && (
                            <Checkbox
                                style={{ transform: 'scale(0.75)', marginRight: 8, marginLeft: 8, padding: 0 }}
                                className={styles.checkbox}
                                onClick={() => this.props.selectedEventHandler(this.props.event.id!)}
                                checked={isSelected}
                                disableRipple={true}
                            />
                        )}
                    </TableCell>
                )}

                <TableCell
                    className={`${styles.eventType} ${isSelected ? styles.rowSelected : ''}`}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    {eventTypeString}
                </TableCell>

                {this.props.showEventId && (
                    <TableCell
                        className={`${styles.videoEventStatus} ${isSelected ? styles.rowSelected : ''}`}
                        onClick={(): void => this.goToDetails(displayEventId)}
                    >
                        {displayEventId}
                    </TableCell>
                )}

                <TableCell
                    className={`${styles.videoEventStatus} ${isSelected ? styles.rowSelected : ''}`}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    {eventStatus}
                </TableCell>

                <TableCell
                    className={`${styles.driver} ${isSelected ? styles.rowSelected : ''}`}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    {this.props.event.driverName?.trim() ? this.props.event.driverName : 'Unknown'}
                </TableCell>

                <TableCell
                    className={`${styles.vehicle} ${isSelected ? styles.rowSelected : ''}`}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    {this.props.event.vehicleDescription || 'Unknown'}
                </TableCell>

                <TableCell
                    className={`${styles.date} ${isSelected ? styles.rowSelected : ''}`}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    <div>{DateFormatter.date(moment(this.props.event.eventStartDate) || moment())} </div>
                    <div>{DateFormatter.timeWithSecondsAndTimezone(moment(this.props.event.eventStartDate) || moment())}</div>
                </TableCell>
                <TableCell
                    className={`${styles.location} ${isSelected ? styles.rowSelected : ''}`}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    <div>
                        {this.props.event.addressStreetNumber || ''} {this.props.event.addressStreetName || ''}
                    </div>
                    <div>
                        {this.props.event.addressCityName || 'Unknown'}, {this.props.event.addressRegion || 'Unknown'}
                    </div>
                </TableCell>
                {scssStyles.styleEnvironment === 'encompass' ? (
                    <TableCell className={styles.mphSingleSpeed} onClick={(): void => this.goToDetails(displayEventId)}>
                        {unit.formatSpeedString(speedStart)}
                    </TableCell>
                ) : (
                    <TableCell
                        className={`${styles.mph} ${isSelected ? styles.rowSelected : ''}`}
                        onClick={(): void => this.goToDetails(displayEventId)}
                    >
                        {unit.formatSpeedString(speedStart)}
                        {', '}
                        {unit.formatSpeedString(speedEnd)}
                        {', '}
                        <div className={styles.mphChangeValue}>{Math.abs(Math.round(speedEnd) - Math.round(speedStart))}</div>
                    </TableCell>
                )}

                <TableCell
                    className={[styles.flexRow, styles.flexRowAllFlagged, isSelected ? styles.rowSelected : ''].join(' ')}
                    onClick={(): void => this.goToDetails(displayEventId)}
                >
                    {(this.props.event.workflowStep === 'Success' ||
                        this.props.event.workflowStep === 'PreparedIdle' ||
                        this.props.event.workflowStep === 'Failed') && (
                        <div>
                            <div className={styles.eventActions}>
                                <div className={styles.action}>
                                    <VideoRatingButton
                                        event={this.props.event}
                                        onRateVideo={this.onRateVideo}
                                        onDismissVideo={this.onDismissVideo}
                                        onClearRating={this.onClearRating}
                                        onReopenEvent={this.onReopenEvent}
                                        selectedRating={this.state.selectedRating}
                                        isDismissed={this.state.isDismissed}
                                        canRate={this.state.canRate}
                                        clearIsEnabled={indicatorText !== 'COMPLETED' ? true : false}
                                        anchor={this.state.rowElement}
                                        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                                        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                                        heightOverride={'28px'}
                                        widthOverride={'28px'}
                                    />
                                </div>
                                {this.props.onFlagVideoEvent && (
                                    <div className={styles.action}>
                                        <FlagButton
                                            eventId={this.props.event.id}
                                            flaggedByUsers={this.state.flaggedByUsers}
                                            onFlagVideoEvent={this.onFlagVideo as (eventId: number | undefined) => void}
                                            customBadgeClass={styles.flagButtonBadge}
                                            customActiveClass={styles.flagButtonActive}
                                            heightOverride={'28px'}
                                            widthOverride={'28px'}
                                        />
                                    </div>
                                )}
                                {this.props.event.workflowStep === 'Success' && (
                                    <div className={styles.action}>
                                        <VideoDownload videoUrl={vidUrl} eventId={eventId} heightOverride="28px" widthOverride="28px" />
                                    </div>
                                )}
                                <div className={styles.action}>
                                    <DeleteButton
                                        eventIds={this.props.event.id ?? 0}
                                        toggleDeletePopup={this.props.toggleDeletePopup}
                                        isDisabled={this.props.deleteButtonDisabled}
                                        heightOverride={'28px'}
                                        widthOverride={'28px'}
                                    />
                                </div>
                            </div>
                            <div className={styles.videoEventFlaggedByUsersCaption}>
                                <VideoEventFlaggedByUsersCaption
                                    flaggedByUsers={this.state.flaggedByUsers}
                                    tooltipPlacement="top-end"
                                    popperDisablePortal={true}
                                />
                            </div>
                        </div>
                    )}
                </TableCell>
            </TableRow>
        );
    }
}

export const VideoEventListRow = ConfigService.inject(IntegrationPartnerDataService.inject(_VideoEventListRow));
