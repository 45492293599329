import { Card, Hidden, Button } from '../../../MaterialUIComponents';
import { bind, React } from '../../../Imports';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IVideoEventServiceInjectedProps, VideoEventService } from '../../../state/VideoEventFreezerService';
import { IVideoRecallServiceInjectedProps, VideoRecallService } from '$State/VideoRecallFreezerService';
import { VideoEventResponse } from '$Generated/api';
import { VideoEventsSidePanel } from '../VideoDetails/DetailsSidePanel/VideoEvents/VideoEventsSidePanel';
import { VideoRecallSidePanel } from '../VideoDetails/DetailsSidePanel/VideoRecall/VideoRecallSidePanel';
import * as _ from 'lodash';


const withWidth = () => (WrappedComponent: any) => {
    const ComponentWithWidth = (props: any) => <WrappedComponent {...props} width="xs" />;
    ComponentWithWidth.displayName = `withWidth(${getDisplayName(WrappedComponent)})`;
    return ComponentWithWidth;
};

function getDisplayName(WrappedComponent: any) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

interface IDetailsSidePanelState {
    expandDetailsPaneOnMobile: boolean;
    showDeletePopup: boolean;
    disabledDeleteButton: boolean
}

const styles = require('./DetailsSidePanel.scss') as {
    main: string;
    expandOnMobile: string;
    mobileEventDetailsToggleButton: string;
};

interface IDetailsSidePanelBaseProps {
    event: VideoEventResponse;
    onStatusChange: (status: string) => void;
    updateVideoEventHistory: () => void;
    eventStatus: string | undefined;
    isStarting: boolean;
    anyVideosPlaying: boolean;
    onPlayButtonClick: () => void;
    videoCount: number;
    width: any;
    onSelectVideoEvent: (eventId: string, goBack: boolean) => void;
    prevNextSplitOn: boolean;
    isVideoRecall?: boolean;
    toggleDeletePopup: (selectedId?: number) => void;
}

type IDetailsSidePanelProps = IDetailsSidePanelBaseProps & IVideoEventServiceInjectedProps & IVideoRecallServiceInjectedProps;

export interface Driver {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

class _DetailsSidePanel extends React.Component<IDetailsSidePanelProps, IDetailsSidePanelState> {
    constructor(props: IDetailsSidePanelProps) {
        super(props);
    }

    state: IDetailsSidePanelState = {
        expandDetailsPaneOnMobile: false,
        showDeletePopup: false,
        disabledDeleteButton: false,
    };

    @bind
    toggleExpandDetailsPaneOnMobile(): void {
        this.setState({ expandDetailsPaneOnMobile: !this.state.expandDetailsPaneOnMobile });
    }

    @bind
    toggleDeletePopup(): void {
        this.setState( { showDeletePopup: !this.state.showDeletePopup });
    };

    @bind
    changeToDisabled(disabled: boolean): void {
        this.setState({disabledDeleteButton: disabled});
    }

    render(): JSX.Element {
        const { expandDetailsPaneOnMobile } = this.state;

        return (
            <Card className={[styles.main, expandDetailsPaneOnMobile ? styles.expandOnMobile : ''].join(' ')}>
                {this.props.isVideoRecall ? (
                    <VideoRecallSidePanel
                        event={this.props.event}
                        eventStatus={this.props.eventStatus}
                        prevNextSplitOn={this.props.prevNextSplitOn}
                        width={this.props.width}
                        videoCount={this.props.videoCount}
                        onPlayButtonClick={this.props.onPlayButtonClick}
                        anyVideosPlaying={this.props.anyVideosPlaying}
                    />
                ) : (
                    <VideoEventsSidePanel
                        anyVideosPlaying={this.props.anyVideosPlaying}
                        event={this.props.event}
                        eventStatus={this.props.eventStatus}
                        onPlayButtonClick={this.props.onPlayButtonClick}
                        onSelectVideoEvent={this.props.onSelectVideoEvent}
                        onStatusChange={this.props.onStatusChange}
                        prevNextSplitOn={this.props.prevNextSplitOn}
                        updateVideoEventHistory={this.props.updateVideoEventHistory}
                        videoCount={this.props.videoCount}
                        width={this.props.width}
                    />
                )}
                <Hidden mdUp>
                    <Button
                        size="small"
                        className={styles.mobileEventDetailsToggleButton}
                        variant="text"
                        onClick={this.toggleExpandDetailsPaneOnMobile}
                    >
                        {expandDetailsPaneOnMobile ? (
                            <FontAwesomeIcon icon={faAngleDown} size="xs" />
                        ) : (
                            <FontAwesomeIcon icon={faAngleUp} size="xs" />
                        )}
                        <br />
                        {expandDetailsPaneOnMobile ? 'Collapse Details' : 'Expand Details'}
                    </Button>
                </Hidden>
            </Card>
        );
    }
}

export const DetailsSidePanel = withWidth()(VideoRecallService.inject(VideoEventService.inject(_DetailsSidePanel)));
